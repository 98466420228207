import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnvironmentVars,
  setHasCameraAccess,
  setProfileData,
  setUserTypePermissions,
  setChildren,
  setSnowFall,
  setOnlineUsers,
  setSchoolLogo,
  setGeneralSettings,
} from "./navbarSlice";
import { removeToken, tokenToCookie } from "../../utils/token-to-cookie";
import { SocketContext } from "../../app/socket";
import { FiMenu } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import {
  BiBarChart,
  BiAbacus,
  BiReceipt,
  BiCategoryAlt,
  BiBook,
  BiCog,
  BiUser,
  BiMinus,
  BiHome,
} from "react-icons/bi";
import { calls } from "../../config/db_config";
import { tokenUtils } from "../../utils/token-utils";
import { iconStyle } from "../../utils/generalUtils";
import ActivateProfile from "../ActivateProfile/activateProfile";
// import Snowfall from "../SnowFall/snowFall";
// import ChatHistory from "../ChatHistory/chatHistory";
// import ChatPopups from "../ChatPopups/chatPopups";

function Navbar() {
  const isRunningInWebView = () => {
    return window.ReactNativeWebView !== undefined;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.value);
  const profileSchoolLogo = useSelector((state) => state.profile.schoolLogo);

  const [userSchools, setUserSchools] = useState([]);

  const [key, setKey] = useState(Date.now());

  // Function to reload the component
  const reloadComponent = (e) => {
    if (window.location.href.includes("quiz")) {
      window.location.reload();
    } else {
      navigate("/quiz");
    }
    // setKey(Date.now()); // Changing the key causes re-mounting
  };

  useEffect(() => {
    const handleIncomingMessage = (eventData) => {
      let data;
      try {
        data = JSON.parse(eventData);
        if (data.message) {
          switch (data.message) {
            case "LOGOUT":
              logout();
              break;
            case "USER_SCHOOLS":
              if (data.data) {
                setUserSchools(data.data);
              }
              break;
            case "CLICKED_SCHOOL":
              const cookie = JSON.parse(data.data.cookie);
              tokenToCookie(cookie, true);
              break;
            default:
              break;
          }
        }
      } catch (e) {
        // Handle JSON parsing error
      }
    };

    const setupMessageListener = () => {
      const isAndroid = /Android/i.test(navigator.userAgent);
      if (isAndroid) {
        document.addEventListener("message", (event) => {
          handleIncomingMessage(event.data);
        });
      } else {
        window.addEventListener("message", (event) => {
          handleIncomingMessage(event.data);
        });
      }
    };

    // Call setupMessageListener to setup the appropriate listener
    setupMessageListener();

    // Cleanup function to remove event listener
    return () => {
      if (/Android/i.test(navigator.userAgent)) {
        document.removeEventListener("message", handleIncomingMessage);
      } else {
        window.removeEventListener("message", handleIncomingMessage);
      }
    };
  }, []);

  useEffect(() => {
    if (profile.email) {
      if (isRunningInWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "NAVBAR_LOADED",
            data: profile.email,
          })
        );
      }
      socketContext.socket.emit("userInfo", profile);
      getActiveUsers();
      return getGeneralSettings();
    }
  }, [profile]);

  const getGeneralSettings = () => {
    let args = {};

    const getGeneralSettingsListener = (data) => {
      dispatch(setGeneralSettings(data));
    };

    const refreshGeneralSettingsListener = () => {
      socketContext.socket.emit("getGeneralSettings", args);
    };

    socketContext.socket.on("generalSettings", getGeneralSettingsListener);
    socketContext.socket.emit("getGeneralSettings", args);
    socketContext.socket.on(
      "refreshGeneralSettings",
      refreshGeneralSettingsListener
    );

    return () => {
      socketContext.socket.off(
        "getGeneralSettings",
        getGeneralSettingsListener
      );
      socketContext.socket.off("generalSettings", getGeneralSettingsListener);
      socketContext.socket.off(
        "refreshGeneralSettings",
        refreshGeneralSettingsListener
      );
    };
  };

  const getActiveUsers = () => {
    const usersListener = (data) => {
      dispatch(setOnlineUsers(data));
    };

    socketContext.socket.on("onlineUsers", usersListener);
    socketContext.socket.emit("getOnlineUsers");
  };

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const paramAllowCameraAccess = searchParams.get("hasCameraAccess");
  if (paramAllowCameraAccess) {
    dispatch(setHasCameraAccess(parseInt(paramAllowCameraAccess)));
  }

  const pathName = useLocation().pathname;

  const socketContext = useContext(SocketContext);

  const [notifications, setNotifications] = useState([]);
  const [mobileActive, setMobileActive] = useState(false);

  const getLogo = () => {
    fetch(calls.getLogoLink, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          dispatch(setSchoolLogo(data.data));

          if (data.data !== "resources/logos/edupalLogo.png") {
            dispatch(setSchoolLogo(data.data));
          } else {
            dispatch(
              setSchoolLogo("https://md.edupal.gr/resources/logos/edupal.png")
            );
          }
        }
      });
  };

  const getEnvironment = () => {
    fetch(calls.getEnvironment, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenUtils.getBearerToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          dispatch(setEnvironmentVars(data.data));
        }
      });
  };

  useEffect(() => {
    getLogo();
    //Enable to test server environment vars
    getEnvironment();
    getNotifications();

    return getUserTypePermissions();
  }, []);

  useEffect(() => {
    if (profile.user_type === "parent") {
      return getChildren();
    }
  }, [profile]);

  const [childrenData, setChildrenData] = useState([]);

  const getChildren = () => {
    let args = { parent_email: profile.email };

    const getChildrenListener = (data) => {
      setChildrenData(data);
      dispatch(setChildren(data));
    };

    const refreshChildrenListener = () => {
      socketContext.socket.emit("getChildren", args);
    };

    socketContext.socket.on("children" + profile.email, getChildrenListener);
    socketContext.socket.emit("getChildren", args);
    socketContext.socket.on("refreshChildren", refreshChildrenListener);

    return () => {
      socketContext.socket.off("getChildren", getChildrenListener);
      socketContext.socket.off("children" + profile.email, getChildrenListener);
      socketContext.socket.off("refreshChildren", refreshChildrenListener);
    };
  };

  const getUserTypePermissions = () => {
    let args = {};

    const getUserTypePermissionsListener = (data) => {
      dispatch(setUserTypePermissions(data));
    };

    const refreshUserTypePermissionsListener = () => {
      socketContext.socket.emit("getUserTypePermissions", args);
    };

    socketContext.socket.on(
      "userTypePermissions",
      getUserTypePermissionsListener
    );
    socketContext.socket.emit("getUserTypePermissions", args);
    socketContext.socket.on(
      "refreshUserTypePermissions",
      refreshUserTypePermissionsListener
    );

    return () => {
      socketContext.socket.off(
        "getUserTypePermissions",
        getUserTypePermissionsListener
      );
      socketContext.socket.off(
        "userTypePermissions",
        getUserTypePermissionsListener
      );
      socketContext.socket.off(
        "refreshUserTypePermissions",
        refreshUserTypePermissionsListener
      );
    };
  };

  const adminLinks = [
    <Link
      className={"link-item home " + (pathName === "/" ? "active" : "")}
      to="/"
    >
      <BiHome
        size={"25px"}
        color={"#858585"}
        style={iconStyle("transparent")}
      />
      Αρχική
    </Link>,
    <Link
      className={"link-item " + (pathName === "/users" ? "active" : "")}
      to="/users"
    >
      <BiCategoryAlt
        size={"25px"}
        color={"#858585"}
        style={iconStyle("transparent")}
      />
      Τμήματα
    </Link>,
    <Link
      className={
        "link-item " +
        (pathName === "/exams" ||
        pathName === "/exams-submitted" ||
        pathName === "/exam"
          ? "active"
          : "")
      }
      to="/exams"
    >
      <BiReceipt
        size={"25px"}
        color={"#858585"}
        style={iconStyle("transparent")}
      />
      Εξέταση
    </Link>,
    <div
      onClick={() => reloadComponent()}
      className={
        "link-item " +
        (pathName === "/quiz" || pathName === "/quiz-admin" ? "active" : "")
      }
      // to={{
      //   pathname: "/quiz",
      //   state: { key: new Date().getTime() },
      // }}
    >
      <BiAbacus
        size={"25px"}
        color={"#858585"}
        style={iconStyle("transparent")}
      />
      Quiz
    </div>,
    <Link
      className={"link-item " + (pathName === "/curriculum" ? "active" : "")}
      to="/curriculum"
    >
      <BiBook
        size={"25px"}
        color={"#858585"}
        style={iconStyle("transparent")}
      />
      Ύλη
    </Link>,
    <Link
      className={"link-item " + (pathName === "/dashboard" ? "active" : "")}
      to="/dashboard"
    >
      <BiBarChart
        size={"25px"}
        color={"#858585"}
        style={iconStyle("transparent")}
      />
      Στατιστικά
    </Link>,
  ];

  const professorLinks = adminLinks;

  const studentInludeLinks = [0, 2, 3, 4];
  const studentLinks = studentInludeLinks.map((index) => adminLinks[index]);

  const parentIncludeLinks = [0, 2];
  const parentLinks = parentIncludeLinks.map((index) => adminLinks[index]);

  const populateLinks = () => {
    const returnLinks = (links) => {
      return links.map(function (element, index) {
        return (
          <li
            className={
              "navbar-container__links-item " +
              (element.props.to === "/" ? "home" : "")
            }
            key={index}
          >
            {element}
          </li>
        );
      });
    };

    if (profile.user_type === "admin") {
      return returnLinks(adminLinks);
    } else if (profile.user_type === "professor") {
      return returnLinks(professorLinks);
    } else if (profile.user_type === "student") {
      return returnLinks(studentLinks);
    } else if (profile.user_type === "parent") {
      return returnLinks(parentLinks);
    }
  };

  function getNotifications() {
    const notificationListener = (data) => {
      setNotifications(data);
    };

    socketContext.socket.on("notification", notificationListener);
    socketContext.socket.emit("getNotifications");
  }

  const wrapperRef = useRef(null);
  useNavBarPopup(wrapperRef);

  function useNavBarPopup(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref) {
          if (
            ref.current &&
            !ref.current.contains(event.target) &&
            event.target.tagName !== "svg" &&
            event.target.className !== "navbar-container__user-icon-img"
          ) {
            if (
              !event.target.classList.contains(
                "navbar-container__user-notification-img"
              )
            ) {
              setpopupOpen(false);
            }
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [popupOpen, setpopupOpen] = useState(false);

  const openedProfilePopupRef = useRef(0);

  useEffect(() => {
    if (popupOpen && openedProfilePopupRef.current === 0) {
      openedProfilePopupRef.current += 1;
    }
  }, [popupOpen]);

  const [schoolLongClick, setSchoolLongClick] = useState(false);
  const longPressTriggered = useRef(false);
  const timerId = useRef(null);

  useEffect(() => {
    const handleWindowClick = (e) => {
      if (!e.target.closest(".school")) {
        setSchoolLongClick(false);
      }
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const handleLongPress = (school) => {
    longPressTriggered.current = true;
    setSchoolLongClick(true);
  };

  const startPressTimer = (school) => {
    longPressTriggered.current = false;
    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => handleLongPress(school), 500);
  };

  const clearPressTimer = () => {
    clearTimeout(timerId.current);
  };

  const handleInteractionEnd = (school, isCurrent) => {
    if (!longPressTriggered.current) {
      schoolClick(school, isCurrent);
    } else {
      longPressTriggered.current = false;
    }
    clearPressTimer();
  };

  const schoolClick = (school, isCurrent) => {
    if (schoolLongClick) {
      if (!isCurrent) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "DELETE_SCHOOL",
              data: school,
            })
          );
        }
        const updatedSchools = userSchools.filter((s) => s !== school);
        setUserSchools(updatedSchools);
      }
    } else if (!isCurrent) {
      if (window.location.href.includes(school.schoolDomain)) {
        try {
          const cookie = JSON.parse(school.cookie);
          tokenToCookie(cookie, true); // Assuming tokenToCookie is a function defined elsewhere
        } catch (e) {
          console.log(e);
        }
      } else {
        window.location.href = school.schoolDomain;
      }
    }
    if (isCurrent) {
      setSchoolLongClick(false);
    }
  };

  const populateUserSchools = () => {
    return userSchools.map((school) => {
      let isCurrent =
        school.email === profile.email &&
        window.location.href.includes(school.schoolDomain);
      let duplicate = userSchools.some(
        (obj) =>
          obj.schoolDomain === school.schoolDomain && obj.email !== school.email
      );

      return (
        <div
          key={"school" + school.schoolDomain + school.email} // Make sure each school has a unique key prop
          onMouseDown={() => startPressTimer(school)}
          onMouseUp={() => handleInteractionEnd(school, isCurrent)}
          onMouseLeave={clearPressTimer}
          onTouchStart={() => startPressTimer(school)}
          onTouchEnd={() => handleInteractionEnd(school, isCurrent)}
          onTouchMove={clearPressTimer}
          className={`school ${isCurrent ? "active" : ""} ${
            schoolLongClick && !isCurrent ? "long" : ""
          }`}
        >
          <div className="school-logo">
            <img src={school.schoolLogo} alt="School Logo" />
          </div>
          <div className="delete-options">
            <BiMinus
              size={"25px"}
              color={"#fff"}
              style={iconStyle("#ff6f6f")}
            />
          </div>
          {duplicate && (
            <span className="school-email">{school.email.split("@")[0]}</span>
          )}
        </div>
      );
    });
  };

  const navUserClick = (event) => {
    setpopupOpen((current) => !current);
  };

  const logout = (event) => {
    if (isRunningInWebView) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "LOGGED_OUT",
          })
        );
      }
    }
    dispatch(setProfileData({}));
    removeToken();

    const body = {
      user_id: profile.user_id,
    };

    fetch(calls.removeUserToken, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenUtils.getBearerToken(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
        }
      });
    navigate("/login");
  };

  const openMobileMenu = () => {
    setMobileActive(!mobileActive);
  };

  const settingsOpen = () => {
    setpopupOpen(false);
  };

  const populateChildrenLinks = () => {
    return childrenData.map((child) => {
      return (
        <Link
          to={"/profile?user-id=" + child.user_id}
          className="navbar-popup__button"
          onClick={() => setpopupOpen(false)}
        >
          <img
            className="navbar-popup__button-profile"
            src={
              child.profile_picture !== ""
                ? child.profile_picture
                : "/resources/student.png"
            }
            alt="profile student professor"
          />
          <span className="child-name">
            {child.first_name + " " + child.last_name}
          </span>
        </Link>
      );
    });
  };

  const [snowfallEnabled, setSnowfallEnabled] = useState(false);

  // const toggleSnowfall = () => {
  //   setSnowfallEnabled(!snowfallEnabled);
  // };

  useEffect(() => {
    dispatch(setSnowFall(snowfallEnabled));
  }, [snowfallEnabled]);

  return (
    <div className="navbar">
      {/* {profile.email == "nikosnalpas@gmail.com" ||
      profile.email == "ath.tsompanis@gmail.com" ? (
        <ChatPopups />
      ) : (
        ""
      )} */}
      {/* <ChatPopups /> */}
      {profile.activated == 2 && profile.user_type == "student" ? (
        <ActivateProfile />
      ) : (
        " "
      )}
      <div className="navbar-container d-flex">
        <div className={"navbar-container__menu-icon"} onClick={openMobileMenu}>
          <FiMenu size={"35px"} color={"#000"} />
        </div>
        <ul className="navbar-container__links">
          <li className="main-logo">
            <Link to="/">
              <img src={profileSchoolLogo} alt={"main-logo"} />
            </Link>
          </li>
          {populateLinks()}
        </ul>
        <div className="navbar-container__user">
          {/* <div className="christmas-mode">
            <div className="checkbox-wrapper">
              <span>❄</span>
              <div className="toggle" onClick={() => toggleSnowfall()}>
                <input
                  name="is_correct_checkbox"
                  checked={snowfallEnabled ? "active" : ""}
                  type="checkbox"
                  hidden
                />
                <label htmlFor="is_correct_checkbox" className="switch" />
              </div>
            </div>
          </div> */}
          {/* {profile.email == "nikosnalpas@gmail.com" ||
          profile.email == "ath.tsompanis@gmail.com" ? (
            <ChatHistory />
          ) : (
            ""
          )} */}
          {/* <ChatHistory /> */}
          <div className="navbar-container__user-icon" onClick={navUserClick}>
            <img
              className="navbar-container__user-icon-img"
              src={
                profile.profile_picture !== ""
                  ? profile.profile_picture
                  : "/resources/student.png"
              }
              alt="profile student professor"
            />
          </div>
        </div>
      </div>
      <div
        ref={wrapperRef}
        className={"navbar-popup " + (popupOpen ? " active " : "")}
      >
        <div>
          <div className="navbar-popup__user">
            <img
              src={
                profile.profile_picture !== ""
                  ? profile.profile_picture
                  : "/resources/student.png"
              }
              alt="profile student edupal"
            />
            <label>{profile.first_name + " " + profile.last_name}</label>
          </div>
        </div>
        <div>
          {profile.user_type !== "parent" && (
            <Link
              to={"/profile"}
              className="navbar-popup__button"
              onClick={() => setpopupOpen(false)}
            >
              <BiUser
                className={" " + (pathName === "/settings" ? "active" : "")}
                size={"22px"}
                color={"#5c5c5c"}
                style={iconStyle("transparent")}
              />
              <span>Προφίλ</span>
            </Link>
          )}
          {userSchools.length ? (
            <div className="navbar-popup__schools">{populateUserSchools()}</div>
          ) : (
            ""
          )}
          {populateChildrenLinks()}
          <div className="navbar-popup__logout">
            <div onClick={logout}>
              <img
                src="../../../resources/icons/logout.svg"
                alt="edupal software"
              />
              <span>Αποσύνδεση</span>
            </div>
            {profile.user_type !== "student" &&
              profile.user_type !== "parent" && (
                <Link
                  to="/settings"
                  className="navbar-popup__settings"
                  onClick={settingsOpen}
                >
                  <BiCog
                    className={" " + (pathName === "/settings" ? "active" : "")}
                    size={"30px"}
                    color={"#5c5c5c"}
                    style={iconStyle("transparent")}
                  />
                </Link>
              )}
          </div>
        </div>
      </div>

      <ul
        className={
          "navbar-container__links-mobile" +
          (mobileActive ? " active" : "") +
          (profile.user_type === "student" ? " students" : "") +
          (profile.user_type === "parent" ? " parents" : "")
        }
      >
        <div className="nav-items">
          {profile.user_type === "student" &&
            studentLinks.map(function (element, index) {
              return (
                <li
                  className={"navbar-container__links-mobile-item"}
                  key={index}
                  onClick={() => {
                    setMobileActive(false);
                    if (index == 0) {
                      navigate("/");
                    } else if (index == 1) {
                      navigate("/exams");
                    } else if (index == 2) {
                      navigate("/quiz");
                    } else if (index == 3) {
                      navigate("/curriculum");
                    }
                  }}
                >
                  {element}
                </li>
              );
            })}
          {profile.user_type === "parent" &&
            parentLinks.map(function (element, index) {
              return (
                <li
                  className={"navbar-container__links-mobile-item"}
                  key={index}
                  onClick={() => {
                    setMobileActive(false);
                    if (index == 0) {
                      navigate("/");
                    } else if (index == 1) {
                      navigate("/exams");
                    }
                  }}
                >
                  {element}
                </li>
              );
            })}
          {(profile.user_type == "admin" || profile.user_type == "professor") &&
            professorLinks.map(function (element, index) {
              return (
                <li
                  className={"navbar-container__links-mobile-item"}
                  key={index}
                  onClick={() => {
                    setMobileActive(false);
                    if (index == 0) {
                      navigate("/");
                    } else if (index == 1) {
                      navigate("/users");
                    } else if (index == 2) {
                      navigate("/exams");
                    } else if (index == 3) {
                      navigate("/quiz");
                    } else if (index == 4) {
                      navigate("/curriculum");
                    } else if (index == 5) {
                      navigate("/dashboard");
                    }
                  }}
                >
                  {element}
                </li>
              );
            })}
        </div>
      </ul>
    </div>
  );
}

export default Navbar;
